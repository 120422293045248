<template>
	<main class="padding-24 bp-1024:padding-64" id="search-view">
		<h1 class="header-font font-size-36 margin-0 margin-b-24 text-brand">
			Site Search
		</h1>
		<div class="gcse-search"></div>
	</main>
</template>

<script>
export default {
	name: 'Search',

	computed: {
		query: function() {
			return this.$route.query.q;
		},
	},

	methods: {
		append_gsce_script() {
			let gsce = document.createElement('script');
			gsce.setAttribute(
				'src',
				'https://cse.google.com/cse.js?cx=d635c28270140c75f',
			);
			gsce.setAttribute('async', '');
			document.getElementById('search-view').appendChild(gsce);
		},
		async get_settings() {
			var response = await fetch(
				`${this.$root.proxy}/services/championships.ashx/get_settings?type=search`,
			);
			var { data } = await response.json();

			return data;
		},
	},

	async created() {
		let settings = await this.get_settings();
		let title = settings.metadata && settings.metadata.title && settings.metadata.title.length > 0
			? settings.metadata.title
			: `Search`;
		let description = settings.metadata && settings.metadata.description && settings.metadata.description.length > 0
			? settings.metadata.description
			: `Search on NCAATickets.com`;
		let image = (settings.metadata && settings.metadata.image && settings.metadata.image.length > 0)
			? this.$root.get_image_resizer_url('https://d230gea5qwdu36.cloudfront.net' + settings.metadata.image)
			: 'https://ncaatickets.com/img/share.jpg';

		title = `Search`;
		description = `Search on NCAATickets.com`;
		image = 'https://ncaatickets.com/img/share.jpg';

		this.setMetaSidearm(title, [
			{
				name: 'description',
				content: description,
			},
			{
				name: 'og:title',
				content: title,
			},
			{
				name: 'og:description',
				content: description,
			},
			{
				name: 'twitter:title',
				content: title,
			},
			{
				name: 'twitter:description',
				content: description,
			},
			{
				name: 'og:image',
				content: image,
			},
			{
				name: 'twitter:image',
				content: image,
			},
		]);
	},

	mounted() {
		this.append_gsce_script();
	},
};
</script>

<style lang="scss">
.gsc-control-cse {
	padding: 0 !important;
}
.gsc-adBlock {
	display: none !important;
}
</style>
